import { Hidden, IconButton, Typography } from "@material-ui/core";
import HoverTitle from "components/custom_v2/HoverTitle";
import UserInfoPopup from "components/theme/UserInfo/UserInfoPopup";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import ProfileImg from "assets/images/kotler/profile.png";
import FeatherIcon from "components/custom/FeatherIcon";
import IntlMessages from "utils/IntlMessages";
import _ from "lodash";
const ProfileDropDown = ({ user }) => {
  const [open, setOpen] = useState(false);
  const hasPermissions = (role, license) =>
    user.roles && user.roles.indexOf(role) !== -1 && user.licenses && _.find(user.licenses, { owner: license });

  return (
    <Dropdown
      className="kotler-drop-down"
      isOpen={open}
      toggle={() => {
        setOpen(!open);
      }}
    >
      <HoverTitle id="My account" placement="left">
        <DropdownToggle tag="span" data-toggle="dropdown">
          <IconButton className="square-icon-buttom " name="profile">
            <div className="app-container-profile">
              <Hidden xsDown>
              <div className="app-profile-text-container">
                <div className="container-profile-name">
                  <Typography
                    variant="body2"
                    component="span"
                    className={`profile-name-text ${user.name || user.lastName ?"text-capitalize":""}`}
                    color="textSecondary"
                    children={user.name || user.lastName ?(user.name + " " + user.lastName):user.email}
                  />
                  <FeatherIcon className={open ? "rotate" : ""} icon="ChevronDown" color="text.secondary" size="11px" />
                </div>
                <Typography variant="caption" component="span" className="profile-type-text" color="textSecondary">
                  {hasPermissions("ROLE_ADMIN", "adm") ? <IntlMessages id="adminRole" /> : null}
                  {hasPermissions("ROLE_SUPERVISOR", "sup") ? <IntlMessages id="supervisorRole" /> : null}
                  {hasPermissions("ROLE_CLIENT", "user") ? <IntlMessages id="userRole" /> : null}
                </Typography>
              </div></Hidden>
              <img width={30} height={30} alt="profile" src={user.avatar || ProfileImg} />
            </div>
          </IconButton>
        </DropdownToggle>
      </HoverTitle>

      <DropdownMenu className="kotler-drop-down-menu"  right>
        <UserInfoPopup
          toggle={() => {
            setOpen(false);
          }}
        />
      </DropdownMenu>
    </Dropdown>
  );
};

export default connect(({ user }) => ({ user }))(ProfileDropDown);
