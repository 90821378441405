import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import UserActions, { useUserCanAiReport } from 'store/reducers/User';
import SettingsActions from 'store/reducers/Settings';
// import _ from 'lodash';
import UserService from 'services/User';
import IntlMessages from 'utils/IntlMessages';
import SessionService from 'services/Session';
/* import { toast } from 'react-toastify';
import _ from 'lodash'; */
import HeadConfig from 'components/custom/HeadConfig';
import { Grid, Hidden } from '@material-ui/core';
import ProfessorDashboardOptions from 'components/layouts/ProfessorDashboardOptions';
/* import KotlerNotification from 'components/custom/kotlerNotification'; */
import SessionInfoBar from 'routes/App/components/User/SessionInfoBar';
import LiveNewsTablet from 'modules/LiveNews/components/LiveNewsTablet';
import LiveMaxVersion from 'components/layouts/LiveMaxVersion';
import WorkgroupStockTicker from 'modules/Workgroups/components/WorkgroupStockTicker';
import ReportIcon from 'assets/images/kotler/dashboardIcons/ai-report.png';
import IconCard from 'components/custom_v2/IconCard';
/* import RoundTestTip from './RoundTestTip'; */

function redirectTo() {
  return <Redirect to="/sessions/add" />;
}

// eslint-disable-next-line max-len
const Dashboard = ( {
  user,
  changeActiveSession,
  toggleAlert,
  toggleLoading,
  history, /* addRoundTestTipShows */
} ) => {
  // const activeSession = UtilService.getUserActiveSession( user );
  // eslint-disable-next-line no-unused-vars
  const [workgroup, setWorkgroup] = useState( {} );
  const canAIReport = useUserCanAiReport();
  const [data, setData] = useState( {} );
  useEffect( () => {
    toggleLoading( true );
    // eslint-disable-next-line no-extra-boolean-cast
    const idActiveSession = !!user.activeSession ? user.activeSession.id : '';
    UserService.getCurrentUser().then( () => {
      toggleLoading( false );
    } );

    SessionService.getSessionCurrentWorkgroupRound( idActiveSession ).then(
      ( response ) => {
        if ( response.ok ) {
          setWorkgroup( response.data );
        }
      },
    );

    SessionService.getSession( idActiveSession ).then( ( response ) => {
      toggleLoading( false );
      if ( !response.ok ) return toggleAlert( response.errors );
      changeActiveSession( response.data );
      setData( response.data );

      if ( !user.activeSession ) {
        return toggleAlert( 'sessionNotConfigured', 'danger', true );
      }
      if ( !user.activeSession.currentRound ) {
        return toggleAlert( 'sessionNoRounds', 'danger', true );
      }

      /* const license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
        if ( !license || !license.workGroup ) {
          return toggleAlert( 'sessionNotConfigured', 'danger', true );
        } */

      if ( response.data.status === 'READY_NO_ROUND' ) {
        return toggleAlert( 'sessionNoRounds', 'danger', true );
      }

      if ( !response.data.status ) {
        return toggleAlert( 'sessionNotConfigured', 'danger', true );
      }
    } );
  }, [!!user.activeSession ? user.activeSession.id : ""]); // eslint-disable-line
  /*  useEffect( () => {
    // eslint-disable-next-line max-len
    if ( user.activeSession && user.activeSession.currentRound
      && user.activeSession.withTestRound && user.activeSession.currentRound.id
      && ( !user.roundTestTipShows
        || !_.find( user.roundTestTipShows, { id: user.activeSession.currentRound.id } ) ) ) {
      setTimeout( () => {
        toast.warning(
          <RoundTestTip
            startDate={user.activeSession.startDate}
            sessionName={user.activeSession.name}
          />,
          {
            position: 'top-left',
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            onClose: () => addRoundTestTipShows( user.activeSession.currentRound.id ),
          },
        );
      }, 3000 );
    }
},[user
  && user.activeSession
  && user.activeSession.currentRound
  && user.activeSession.currentRound.id])// eslint-disable-line
  */
  // eslint-disable-next-line no-extra-boolean-cast
  if ( !!!user.activeSession ) {
    return redirectTo();
    // window.location.assign( '/sessions/add' );
  }
  return (
    <>
      <HeadConfig
        breadcrumbsLinks={[
          {
            url: '/',
            name: 'HomeLink',
          },
        ]}
      />
      <div className="w-100 h-100 d-flex flex-column  flex-grow-1 bg-transparent">
        <div className=" w-100 flex-grow-1 d-flex flex-column ">
          <SessionInfoBar data={data} workgroup={workgroup} />
          <Grid
            container
            style={{ width: '100vw' }}
            justify="flex-end"
            className="px-4"
          >
            <Grid item>
              <Button
                key="save"
                variant="contained"
                color="primary"
                className="jr-btn jr-btn-lg mt-3"
                onClick={() => {
                  history.push( '/sessions/add' );
                }}
              >
                <i className="fa fa-plus" />
                <IntlMessages id="createSessionBtn" />
              </Button>
            </Grid>
          </Grid>
          <div
            style={{ scrollSnapType: 'y mandatory' }}
            className="flex-grow-1  d-flex flex-column position-relative "
          >
            {user && user.activeSession && (
              <div
                className="w-100 d-flex justify-content-center"
                style={{ minHeight: '80vh', scrollSnapAlign: 'start' }}
              >
                <div className="container-dashboard-options-professor">
                  {/* STUDIO AND ENTERPRISE OPTION COMPONENT  DONT CANT FUNCTION WITHOT GRID */}
                  <ProfessorDashboardOptions /* mkmode={mkmode} */ />
                  {/*  {CarrouselData.length > 0 && ( */}

                  {canAIReport && (
                    <div>
                      <IconCard
                        image={<img src={ReportIcon} alt="company icon livemax section" />}
                        title="AI Report"
                        link={`/sessions/${user.activeSession.id}/ai-report`}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}

            {!!(
              user
              && user.activeSession
              && user.activeSession.distributionType === 'enterprise'
              && !user.activeSession.withTestRound
            ) && (
              <Hidden implementation="js" smDown>
                <div className="d-flex w-100 justify-content-center">
                  <Grid container justify="center">
                    <Grid item xs={false} lg={1} />
                    <Grid item lg={7}>
                      <LiveNewsTablet />
                    </Grid>
                  </Grid>
                </div>
              </Hidden>
            )}
            <LiveMaxVersion
              style={{
                position: 'absolute',
                minHeight: '0px',
                height: '20px',
                bottom: 5,
                right: 0,
                left: 0,
                zIndex: 1,
              }}
            />
          </div>
          <WorkgroupStockTicker workgroups={data && data.workGroups} />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ( { user } ) => ( { user } );
const mapDispatchToProps = {
  changeActiveSession: UserActions.changeActiveSession,
  addRoundTestTipShows: UserActions.addRoundTestTipShows,
  toggleLoading: SettingsActions.toggleLoading,
  toggleAlert: SettingsActions.toggleAlert,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( Dashboard );
