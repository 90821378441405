import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import {
  Button,
  Container,
  Grid,
  Hidden,
  Typography,
  IconButton,
} from '@material-ui/core';
import { Col, Row } from 'reactstrap';

import async from 'async';
import _ from 'lodash';

import { composeValidators, email, required } from 'config/InputErrors';
import TextField from 'components/custom/FormElements/TextField';
/* import TextEnviroment from 'components/custom_v2/TextEnviroment'; */
import IntlMessages from 'utils/IntlMessages';
import UserActions from 'store/reducers/User';
import SettingsActions from 'store/reducers/Settings';
import AuthService from 'services/Auth';
import UserService from 'services/User';

/* import videoWelcome from 'assets/media/welcome.mp4';
import ValidateCodeModal from 'components/custom/ValidateCodeModal'; */
// assets
import logo from 'assets/images/logo-livemax-kotler.png';
import BackgroundImage from 'assets/images/kotler/login-background.jpeg';
import FeatherIcon from 'components/custom/FeatherIcon';
import CodeToRegisterModal from 'components/layouts/CodeToRegisterModal';
import LiveMaxVersion from 'components/layouts/LiveMaxVersion';
import ForgotPassword from 'components/layouts/ForgotPassword';
import { LanguageButton } from 'components/custom/LanguageButton';

class SignIn extends React.Component {
  constructor() {
    super();
    this.state = {
      openCodeToRegister: false,
      //   showVideo: false,
    };
  }

  componentDidMount() {
    const { accessToken, isLoading, logoutUser, toggleLoading } = this.props;

    if ( accessToken ) logoutUser();
    // if ( accessToken && true ) history.push( '/' );
    if ( isLoading ) toggleLoading( false );

    /* setTimeout( () => {
      this.setState( {
        showVideo: true,
        opa: '0',
        opa2: '100',
      } );
    }, 7000 );
    */
  }

  unMuted = () => {
    const video = document.getElementsByTagName( 'video' )[0];
    video.muted = false;
  };

  loginUser = ( data, cb ) => {
    AuthService.loginUser( data ).then( response => cb( response.errors, response.data ) );
  };

  getNotificationsUser = ( data, cb ) => {
    UserService.getNotificationsUser( data ).then( ( response ) => {
      if ( response.errors ) return cb( response.errors );
      return cb( null, { ...data, notifications: response.data } );
    } );
  };

  getUser = ( data, cb ) => {
    UserService.getCurrentUser( data ).then( ( response ) => {
      if ( response.errors ) return cb( response.errors );
      return cb( null, { ...data, ...response.data } );
    } );
  };

  hasPermissions = ( user, role, license ) => user.roles
    && user.roles.indexOf( role ) !== -1
    && user.licenses
    && _.find( user.licenses, { owner: license } );

  submitForm = ( data ) => {
    const {
      loginUser,
      changeActiveSession,
      toggleLoading,
      toggleAlert,
      history,
      toggleVideo,
      toggleTip,
    } = this.props;

    toggleLoading( true );
    toggleVideo( true );
    toggleTip( true );

    async.waterfall(
      [
        this.loginUser.bind( this, data ),
        this.getUser,
        this.getNotificationsUser,
      ],
      ( error, results ) => {
        toggleLoading( false );
        if ( error ) {
          if ( error === 'invalid_grant' ) {
            return toggleAlert( 'wrongUserPass', 'danger', true );
          }
          return toggleAlert( error );
        }
        if ( _.get( results, 'licenses[0].session' ) ) {
          changeActiveSession( results.licenses[0].session );
          loginUser( results );
          history.push( '/' );
        } else {
          loginUser( results );
          history.push( '/sessions/add' );
        }
      },
    );
  };
  // eslint-disable-next-line max-len
  toggleCodeToRegister = () => this.setState( prev => ( { openCodeToRegister: !prev.openCodeToRegister } ) );

  render() {
    const { openCodeToRegister } = this.state;
    const { history } = this.props;
    return (
      <div
        className="app-login-container position-relative overflow-auto d-flex flex-column   slideInUpTiny animation-duration-3 bg-image  "
        style={{ backgroundImage: `url(${BackgroundImage})` }}
      >
        <Hidden smDown>
          <div
            style={{
              background:
                'linear-gradient(270.87deg, #FFFFFF 33.81%, rgba(255, 255, 255, 0) 96.88%)',
              position: 'absolute',
              top: 0,
              bottom: 0,
              right: 0,
              left: '50%',
            }}
          />
        </Hidden>
        <Hidden mdUp>
          <div
            style={{
              background: 'rgba(255,255,255,.4)',
              position: 'absolute',
              bottom: 0,
              right: 0,
              left: 0,
              top: 0,
            }}
          />
        </Hidden>

        <div
          className="d-flex h-100 w-100 flex-column  overflow-auto position-relative"
          style={{ zIndex: 1 }}
        >
          <div className="d-flex w-full   justify-content-end   mr-5">
            <div
              style={{
                backgroundColor: 'trasparent',
                marginRight: 20,
                marginTop: 20,
              }}
            >
              <a
                href="https://mk21support.cencoapps.com/?lang=es_ES"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'grey', textDecoration: 'none' }}
              >
                <span>
                  <IntlMessages id="appModule.contactSupport" />
                </span>
                <IconButton className="icon-btn square-icon-buttom">
                  <FeatherIcon icon="headphones" size="18px" />
                </IconButton>
              </a>
            </div>
            <LanguageButton className="position-static" />
          </div>
          <Container
            maxWidth="xl"
            style={{ zIndex: 2 }}
            className="d-flex  flex-grow-1 my-5 "
          >
            <Grid
              container
              className="d-flex flex-grow-1  justify-content-end w-100"
            >
              <Grid
                item
                xs={12}
                md={8}
                lg={6}
                xl={4}
                className="d-flex flex-grow-1 justify-content-center "
              >
                <Grid container justify="center" alignItems="center">
                  <Grid item xs={11} sm={5} md={6} lg={6}>
                    <div
                      className="d-flex flex-column"
                      style={{ rowGap: '30px' }}
                    >
                      <Link to="/" title="LiveMax" replace>
                        <img
                          alt="LiveMaX-Kotler-logo"
                          src={logo}
                          className="logo-login"
                        />
                      </Link>
                      <Row>
                        <Col>
                          <div className="d-flex justify-content-between ">
                            <Typography
                              variant="subtitle1"
                              className="intro-login-text"
                            >
                              <IntlMessages id="loginIntroText" />
                            </Typography>
                          </div>
                        </Col>
                      </Row>
                      <Form
                        onSubmit={this.submitForm}
                        render={( { handleSubmit } ) => (
                          <form onSubmit={handleSubmit} noValidate>
                            <Row>
                              <Col>
                                <TextField
                                  containerClass="kotler-text-field-white-form-control mb-3"
                                  className="kotler-text-field-white"
                                  startAdornment={<FeatherIcon icon="User" />}
                                  field="username"
                                  autoComplete="username"
                                  label="email"
                                  marginContainer={false}
                                  validate={composeValidators( required, email )}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <TextField
                                  containerClass="kotler-text-field-white-form-control mb-3"
                                  className="kotler-text-field-white"
                                  marginContainer={false}
                                  startAdornment={<FeatherIcon icon="lock" />}
                                  field="password"
                                  type="password"
                                  label="password"
                                  autoComplete="current-password"
                                  validate={required}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <ForgotPassword />
                              </Col>
                            </Row>

                            <Grid
                              container
                              className="flex-column-reverse flex-sm-row "
                            >
                              <Grid
                                item
                                xs={12}
                                sm={5}
                                className="mt-3 mt-sm-0"
                              >
                                <Button
                                  fullWidth
                                  style={{ minWidth: 112 }}
                                  variant="outlined"
                                  color="primary"
                                  className="kotler-button-lg px-0 "
                                  onClick={this.toggleCodeToRegister}
                                >
                                  <IntlMessages id="appModule.signIn" />
                                </Button>
                              </Grid>
                              <Grid item xs={12} sm={5} className="ml-sm-3 ">
                                <Button
                                  fullWidth
                                  style={{ minWidth: 112 }}
                                  variant="contained"
                                  color="primary"
                                  className="kotler-button-lg px-0"
                                  type="submit"
                                >
                                  <IntlMessages id="loginBtn" />
                                </Button>
                              </Grid>
                            </Grid>
                          </form>
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
          <LiveMaxVersion>
            <div className="d-flex  flex-1 " style={{ flexGrow: 1 }}>
              <Button
                variant="text"
                color="default"
                className="hr-button"
                onClick={() => history.push( '/privacy' )}
              >
                <IntlMessages id="privacy.title" />
              </Button>
            </div>
          </LiveMaxVersion>
        </div>

        <CodeToRegisterModal
          open={openCodeToRegister}
          onClose={this.toggleCodeToRegister}
          history={history}
        />
        {/*  {showVideo && (
          <video
            onClick={this.unMuted.bind( this )}
            preload="true"
            autoPlay
            muted
            volume="0"
            width="810"
            height="402"
            className="app-login-main-content"
            style={{
              padding: '0px',
               border: '0px',
                position: 'absolute',
                 zIndex: opa2,
                  borderRadius: '10px', opacity: opa, width: `${opa}%`, transitionDuration: '2s' }}
          >
            <source src={videoWelcome} />
          </video>

        )}
        */}
      </div>
    );
  }
}

const mapStateToProps = ( { user, settings } ) => ( {
  accessToken: user.access_token,
  isLoading: settings.isLoading,
} );

const mapDispatchToProps = {
  loginUser: UserActions.loginUser,
  logoutUser: UserActions.logoutUser,
  toggleLoading: SettingsActions.toggleLoading,
  toggleAlert: SettingsActions.toggleAlert,
  toggleVideo: SettingsActions.toggleVideo,
  toggleTip: SettingsActions.toggleTip,
  notificationsUser: UserActions.notificationsUser,
  changeActiveSession: UserActions.changeActiveSession,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( SignIn );
